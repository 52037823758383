// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"

Rails.start()

window.addEventListener('load', () => {
	function showGalleryPicture(event) {
		event.preventDefault()
		var slide_id = event.currentTarget.getAttribute('data-target-slide-id')
		document.querySelector('.sliderActive[data-slide-id]').classList.remove("sliderActive")
		document.querySelector('.sliderActive[data-target-slide-id]').classList.remove("sliderActive")
		document.querySelector('[data-target-slide-id="' + slide_id + '"]').classList.add("sliderActive")
		document.querySelector('[data-slide-id="' + slide_id + '"]').classList.add("sliderActive")
	}

	var gallery_buttons = document.querySelectorAll("a[data-target-slide-id]");
	for (let i = 0; i < gallery_buttons.length; i++) {
		gallery_buttons[i].addEventListener("click", showGalleryPicture);
	}
});

window.addEventListener('load', (event) => {
	function handler(entries, observer) {
  	for (var entry of entries) {
    	if (entry.isIntersecting) {
				document.getElementById('websiteHeader').className = 'websiteHeader';
    		document.getElementById('websiteMainContent').className = 'websiteMainContent';
			} else {
				document.getElementById('websiteHeader').className = 'websiteHeader websiteHeader--sticky';
    		document.getElementById('websiteMainContent').className = 'websiteMainContent websiteMainContent--sticky';
			}
  	}
	}

	var statusBox = document.getElementById("websiteHeader");
	let observer = new IntersectionObserver(handler);
	observer.observe(document.getElementById("target"));
});
